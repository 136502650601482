import { Col, Row, Spin } from "antd";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { messagesCollectionRef } from "../../firebase/fire-app";

const OthersOpinions = () => {
    // const [messages, setMessages] = useState(null);
    // const [loading, setLoading] = useState(null);

    const [messages, loading, error] = useCollectionData(messagesCollectionRef.where('isPublic', "==", true).orderBy('date', 'desc').limit(30), { idField: 'id' })
    if (error) {
        console.log(error);
    }
    return (
        <>
            {/* <SjnImgSection/> */}

            <Row gutter={{ xs: 0, sm: 0, md: 14, lg: 18 }} className="mt-4">
                
                <h4 className="text-white text-center font-26">Last {messages && messages.length} messages</h4>

                {messages && messages.map(item => <MessageComponent message={item} key={item.id} />)}
                {loading && <div className="bg-white p-4  ml-4">
                    <Spin />
                </div>}
            </Row>
        </>
    );
};

export default OthersOpinions;

const MessageComponent = ({ message }) => {
    return (
        <><Col sm={24} lg={24} className="gutter-row" style={{ marginBottom: "18px" }} >
            <div className="bg-white p-3 text-left mx-4">
                {message && <p className="font-16">{message.message && message.message} </p>}
                <small><i>{message.reply ? "Swajan has replied to this message!" : <span className="text-important">Swajan has not replied yet ):""</span>} </i>  </small>
                {/* <small>Replied: <span className="font-bold ">{message.reply ? <span className="text-important">Yes</span> : 'Not yet'} </span> </small> */}
            </div>
        </Col>

        </>
    )
}
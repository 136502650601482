import SjnImgSection from "../../components/SjnImg/SjnImgSection"
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { messagesCollectionRef } from "../../firebase/fire-app";
import { Spin } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useEffect, useState } from "react";
import { HeartFilled } from '@ant-design/icons';
import { notification } from 'antd';




const MessageReply = () => {
    const location = useLocation()
    // console.log(location.pathname);
    const [replySeen, setReplySeen] = useState(false);

    const openNotification = (replyMessage) => {
        notification.open({
            message: replyMessage,
            description: 'reply from স্বজন ❤️',
            icon: <HeartFilled style={{ color: '#ff0000' }} />,
            duration: 10,
        });
    };
    const { messageId } = useParams();
    // console.log(messageId);
    const [message, loading, error] = useDocumentData(messagesCollectionRef.doc(messageId));
    useEffect(() => {
        if (!replySeen) {
            if (message) {
                if (message.reply) {
                    if (!message.replySeen) {
                        messagesCollectionRef.doc(messageId).update({
                            replySeen: true
                        }).then(() => {
                            // console.log("updated")
                            setReplySeen(true)
                        })
                    }
                }
            }
        }
    }, [replySeen, message])
    if(error){
        console.log(error);
    }
    useEffect(() => {
        if (message && message.reply) {

            openNotification(message.reply)
        }
    }, [message])
    return (
        <>
            <SjnImgSection />
            <div className="message-section">
                <div className="message-card">
                    <div className="sul-box-raised-2 with-hover p-4 text-left">
                        {loading && <Spin />}
                        {message && <div>
                            <small className="font-bold">
                            এইটা তোমার({message.name ? message.name : '*no hints'}) message
                                </small>

                            </div>}
                        {message && <p className="font-18">{message.message && message.message} </p>}
                    </div>
                    <div className="sul-box-inset-1 mt-5 with-hover p-4 text-right">
                        <small className="font-bold">এইটা স্বজন এর reply</small>
                        {message && <div>{message.reply ? <p className="font-18"> {message.reply}  </p> : <NoReplyYet messageId={messageId} />}</div>}
                    </div>
                </div>

            </div>
        </>
    );
};

export default MessageReply;

const NoReplyYet = ({ messageId }) => {
    return (
        <>
            <p> স্বজন-তো এখনও লিখে নায়। একটু পর আবার আসতে পারো। লিংকটা কপি করে নাও।</p>
            <CopyToClipboard text={`https://hello.swajan.io/message/${messageId}`}>
                <button className="ant-btn ant-btn-primary">Copy link</button>
            </CopyToClipboard>
        </>
    )
}
import { BrowserRouter as Router } from "react-router-dom"
import SiteLayout from './components/Layout/Layout';
import Main from './views/Main';

function App() {
  return (
    <>
      <Router>
        <SiteLayout>
          <Main />
        </SiteLayout>
      </Router>
    </>
  );
}

export default App;

import { Route, Switch } from "react-router-dom";
import Home from "./Home/Home"
import ViewReply from "./Reply/ViewReply";
import { useEffect, useState } from 'react';
import MessageReply from "./MessageReply/MessageReply";
import OthersOpinions from "./OthersOpinions/OthersOpinions";


const Main = () => {
    const [showMenu, setShowMenu] = useState(false)
    useEffect(() => {
        if (localStorage.getItem('msgId')) {
            setShowMenu(localStorage.getItem('msgId'))
        }
    }, [localStorage.getItem('msgId')])
    return (
        <>
            <Switch>
                <Route exact path="/" >
                    <Home />
                </Route>
                <Route exact path="/others-messages" >
                    <OthersOpinions />
                </Route>
                {showMenu && <Route exact path="/reply">
                    <ViewReply messageId={showMenu} />
                </Route>}
                <Route exact path="/message/:messageId">
                    <MessageReply />
                </Route>
            </Switch>
        </>
    );
};

export default Main;
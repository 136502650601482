import React from 'react';
import ReactDOM from 'react-dom';

// ant design styles
import "../node_modules/antd/dist/antd.less";
import "../node_modules/antd/lib/style/themes/default.less";
import "./styles/default-colors.less";
import "../node_modules/soft-ui-library/dist/css/neumorphism-ui.css"

import './index.css';
import "./styles/styles.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./styles/responsive.scss"
console.log = function() {}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


import { useState } from "react";
import { useForm } from "react-hook-form";
// import { useHistory } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import SjnImgSection from "../../components/SjnImg/SjnImgSection"
import { messagesCollectionRef } from "../../firebase/fire-app";

const Home = () => {
    const [showModal, setShowModal] = useState(false);
    // const history = useHistory();

    const {register, handleSubmit} = useForm();
    const [message, setMessage] = useState()

    const onMessageSend=(data, e)=>{
        if(localStorage.getItem("msgId")){
            data.previousMessage = localStorage.getItem("msgId");
        }
        data.isPublic = true;
        data.date = new Date();
        data.reply = false;
        data.replySeen = false;

        messagesCollectionRef.add(data).then((docRef)=>{
            localStorage.setItem('msgId',docRef.id);
            e.target.reset();
            setMessage(data);
            setShowModal(true);
        })
        setShowModal(true)
    }
    return (
        <>
        <SjnImgSection/>
        <div className="message-section">

            <div className="message-card">
                <form onSubmit={handleSubmit(onMessageSend)} >
                    
                    
            <input {...register("name")}   type="text" className="sul-text-field" placeholder="তোমার নামের hints দিবা?😒 (optional)" />

            <textarea {...register("message", {required: true}) }  className="sul-text-field" rows="5" placeholder="ভালো কথা লিখবা কিন্তু 😮"  ></textarea>

            <button type="submit" className="sul-btn">সেন্ড করে দাও ❤️</button>

            </form>
            </div>
            
        </div>
        <ConfirmationModal message={message} showModal={showModal} setShowModal={setShowModal}/>
        </>
    );
};

export default Home;
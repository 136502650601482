import AntdMenu from "./AntdMenu"
import "./Layout.css"




const SiteLayout = ({ children }) => {
    return (
        <>
            <AntdMenu />

            <div className="ligt-mode container">
                {children}
            </div>

        </>
    );
};

export default SiteLayout;
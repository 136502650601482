import SjnImgSection from "../../components/SjnImg/SjnImgSection"
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { messagesCollectionRef } from "../../firebase/fire-app";
import { Spin } from "antd";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { notification } from 'antd';
import { HeartFilled } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";



const ViewReply = ({ messageId }) => {
    // const messageId = localStorage.getItem("msgId");
    const location = useLocation()
    const urlPath = location.pathname;
    const [replySeen, setReplySeen] = useState(false);
    const openNotification = (replyMessage) => {
        notification.open({
            message: replyMessage,
            description: 'reply from স্বজন ❤️',
            icon: <HeartFilled style={{ color: '#ff0000' }} />,
            duration: 10,
        });
    };
    const [message, loading, error] = useDocumentData(messagesCollectionRef.doc(messageId));
    useEffect(() => {
        if (!replySeen) {
            if (message) {
                if (message.reply) {
                    if (!message.replySeen) {
                        messagesCollectionRef.doc(messageId).update({
                            replySeen: true
                        }).then(() => {
                            // console.log("updated")
                            setReplySeen(true)
                        })
                    }
                }
            }
        }
    }, [replySeen, message])
    // if (message) {
    //     if (message.reply) {
    //         messagesCollectionRef.doc(messageId).update({
    //             replySeen: true
    //         }).then(() => {
    //             console.log("updated")
    //         })
    //     }
    // }
    if (error) {
        console.log(error)
    }

    useEffect(() => {
        if (message && message.reply) {
            if (urlPath == "/reply") {
                console.log("oka");
                openNotification(message.reply)

            }
            // openNotification(message.reply)
        }
    }, [message])


    return (
        <>
            <SjnImgSection />
            <div className="message-section">
                <div className="message-card">
                    <div className="sul-box-raised-2 with-hover p-4 text-left">
                        {loading && <Spin />}
                        {message && <div>
                            <small className="font-bold">এইটা তোমার ({message.name ? message.name : '*no hints'}) message  </small>

                            <p className="font-18">
                                <span className="font-bold"> {message.message && message.message}</span> </p>
                        </div>}
                    </div>
                    <div className="sul-box-inset-1 mt-5 with-hover p-4 text-right">
                        {message && <div>{message.reply ? <YesReply reply={message.reply} /> : <NoReplyYet messageId={messageId} />}</div>}
                    </div>
                </div>

            </div>
        </>
    );
};

export default ViewReply;

const NoReplyYet = ({ messageId }) => {
    return (
        <>
            <p> স্বজন-তো এখনও লিখে নায়। একটু পর আবার আসতে পারো। লিংকটা কপি করে নাও।</p>
            <CopyToClipboard text={`https://hello.swajan.io/message/${messageId}`}>
                <button className="ant-btn ant-btn-primary">Copy link</button>
            </CopyToClipboard>
        </>
    )
}

const YesReply = ({ reply }) => {
    return (
        <>
            <small className="font-bold">
                এইটা স্বজন এর reply
                 </small>
            <p className="font-18"> {reply}  </p>
        </>
    )
}
import {  SwajanImg } from "../../img";
import "./SjnImgSection.scss"

const SjnImgSection = () => {
    return (
        <>
            <div className="img-container text-center">
                <div className="servers-icon">
                    
                {/* <img src="/swajan-mask.jpeg" alt="swajan" /> */}
                <img src="/swajan.jpeg" alt="swajan" />

                </div>
            </div>
        </>
    );
};

export default SjnImgSection;
import { Layout, Menu } from 'antd';
import {  MessageOutlined, HeartOutlined,UsergroupDeleteOutlined, SmileOutlined, FacebookFilled, LinkedinFilled, TwitterCircleFilled, GithubFilled, InstagramFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import "./AntdMenu.scss"
// import { useAuth } from '../../Hooks/useAuth';
const {  Sider } = Layout;


const AntdMenu = () => {
    // const { hasAccess } = useAuth();
    const [showMenu, setShowMenu] = useState(false)
    useEffect(()=>{
        if(localStorage.getItem('msgId')){
            setShowMenu(localStorage.getItem('msgId'))
        }
    },[localStorage.getItem('msgId')])

    return (
        <>
            <Sider
                title="Prottoy"
                style={{
                    height: '100vh',
                    position: 'fixed',
                    top: "0",
                    left: "0",
                    zIndex: "20"
                }}
                breakpoint="sm"
                collapsedWidth="0"
            // onBreakpoint={broken => {
            //     console.log(broken);
            // }}

            // onCollapse={(collapsed, type) => {
            //     console.log(collapsed, type);
            // }}


            >
                <Menu theme="dark" mode="inline" defaultSelectedKeys={[showMenu ? "1" : "5"]}>
                    <h1 className="text-white mb-4 text-center mt-5 mt-3">স্বজন</h1>
                
                        <Menu.Item key="1" icon={<MessageOutlined />}>
                            <Link className="mt-2" to="/">Send Message</Link>
                        </Menu.Item>
                    {showMenu &&
                        <Menu.Item key="2" icon={<HeartOutlined />}>
                            <Link className="mt-2" to="/reply">Swajan's Reply</Link>
                        </Menu.Item>}
                        <Menu.Item key="3" icon={<UsergroupDeleteOutlined />}>
                            <Link className="mt-2" to="/others-messages">Others' Messages</Link>
                        </Menu.Item>
                        <Menu.Item key="4" icon={<SmileOutlined />}>
                            {/* <Link className="mt-2" to="/others-messages">Others' Messages</Link> */}
                            <a href="http://swajan.io" target="_blank" rel="noopener noreferrer">Visit my site <span className="font-20">😗</span> </a>
                        </Menu.Item>
                        <div className="mt- socials ml-3">
                        <a href="https://fb.com/smswajan" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center ml-2 mb-2">
                            <div className="text-success"><FacebookFilled />  </div>
                            <p className="text-white mb-0 mt-2 ml-2"> {" "} <small> /smswajan</small></p>
                        </a>
                        <a href="https://linkedin.com/in/smswajan" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center ml-2 mb-2">
                            <div className="text-success"><LinkedinFilled />  </div>
                            <p className="text-white mb-0 mt-2 ml-2"> {" "} <small> /in/smswajan</small></p>
                        </a>
                        <a href="https://twitter.com/smswajan" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center ml-2 mb-2">
                            <div className="text-success"><TwitterCircleFilled />  </div>
                            <p className="text-white mb-0 mt-2 ml-2"> {" "} <small> /smswajan</small></p>
                        </a>
                        <a href="https://github.com/smswajan" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center ml-2 mb-2">
                            <div className="text-success"><GithubFilled />  </div>
                            <p className="text-white mb-0 mt-2 ml-2"> {" "} <small> /smswajan</small></p>
                        </a>
                        <a href="https://instagram.com/smswajan" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center ml-2 mb-2">
                            <div className="text-success"><InstagramFilled />  </div>
                            <p className="text-white mb-0 mt-2 ml-2"> {" "} <small> /smswajan</small></p>
                        </a>
                    </div>

                </Menu>
            </Sider>
            {/* <div className="d-none d-md-block">
                <Sider
                    title="Prottoy"
                    style={{
                        height: '100vh',
                    }}
                    breakpoint="sm"
                    collapsedWidth="0"
                // onBreakpoint={broken => {
                //     console.log(broken);
                // }}

                // onCollapse={(collapsed, type) => {
                //     console.log(collapsed, type);
                // }}


                >
                    <div className="logo" />
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={[showMenu ? "1" : "5"]}>
                        <h3 className="text-white text-center mt-3">Prottoy</h3>
                        <hr />
                        {showMenu &&
                            <Menu.Item key="1" icon={<UserOutlined />}>
                                <Link className="mt-2" to="/">All blogs</Link>
                            </Menu.Item>}
                        {showMenu &&
                            <Menu.Item key="2" icon={<VideoCameraOutlined />}>
                                <Link className="mt-2" to="/add/blog">add blog</Link>
                            </Menu.Item>}

                        {showMenu && <Menu.Item key="3" icon={<UploadOutlined />}>
                            <Link className="mt-2" to="/my-sales">my sales</Link>
                        </Menu.Item>}
                        {showMenu && <Menu.Item to="/withdraws" key="4" icon={<UserOutlined />}>
                            <Link className="mt-2" to="/withdraws">withdraws</Link>
                        </Menu.Item>}
                        <Menu.Item key="5" icon={<UserOutlined />}>
                            <Link className="mt-2" to="/login"> Login</Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
            </div> */}


        </>
    );
};

export default AntdMenu;
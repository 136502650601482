import {useState, useEffect} from 'react'
import { Modal, Button } from 'antd'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

const ConfirmationModal = ({showModal, setShowModal, message}) => {
    const [messageId, setMessageId] = useState(localStorage.getItem("msgId"));
    useEffect(()=>{
        if(localStorage.getItem("msgId")){
            setMessageId(localStorage.getItem("msgId"))
            // console.log("updated", localStorage.getItem("msgId"))
        }
    },[localStorage.getItem("msgId")])

    const onModalShow = () => {
        setShowModal(!showModal)
    }
    return (
        <>
            {/* <Button type="primary" onClick={onModalShow} >Add new Category</Button> */}

            <Modal centered={true} title="স্বজনকে বার্তা পাঠানো হয়েছে ❤️" visible={showModal} onOk={onModalShow} onCancel={onModalShow}
                footer={[
                    <Button type="primary" onClick={showModal}>
                        Close
                    </Button>,
                    <Link to={`/message/${messageId}`} className="ant-btn ant-btn-primary" >Swajan's reply</Link>
                ]}>
                {/* <h2>New message sent to Swajan</h2> */}
                <p className="font-16" >" <i>{message && message.message} </i> " </p>

                <p className="mt-5 font-14">একটু সময় দাও🤯। তারাতাড়ি  reply দিচ্ছি। নিচের লিংকে reply পাবে।</p>
                <p className="mt-2" ><small> <i> https://hello.swajan.io/message/{messageId}</i> </small></p>
                <CopyToClipboard text={`https://hello.swajan.io/message/${messageId}`}>
                    <button className="ant-btn ant-btn-primary">Copy link</button>
                </CopyToClipboard> {" "}
                <Link to={`/message/${messageId}`} className="ant-btn ant-btn-primary" >Visit now</Link>
            </Modal>
        </>
    );
};

export default ConfirmationModal;